import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import recentOperationsDatabase from '@/services/offline/registries/registriesRecentOperations.database.js'
import _ from 'lodash'

class enablingTypeCategoryManagment {

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/enablingTypeCategories/list', {
        page: page,
        rowPerPage: rowPerPage,
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  create(enablingTypeCategory, recentOperation=true) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/enablingTypeCategories/create', enablingTypeCategory).then((response) => {
        if (response.success) {
          resolve(response.results)

          if (recentOperation) {
            recentOperationsDatabase.pushOperation(this._buildRecentOperationObject('create', enablingTypeCategory))
              .then(() => { })
          }
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  update(enablingTypeCategory, recentOperation=true) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/enablingTypeCategories/update', enablingTypeCategory).then((response) => {
        if (response.success) {
          resolve(response.results)

          if (recentOperation) {
            recentOperationsDatabase.pushOperation(this._buildRecentOperationObject('update', enablingTypeCategory))
              .then(() => { })
              .catch((err) => { console.log(err) })
          }
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  delete(enablingTypeCategory) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!enablingTypeCategory.id) {
        reject('id undefined')
      }

      axios.get(apiUrl + '/registries/enablingTypeCategories/delete', {
        id: enablingTypeCategory.id
      }).then((response) => {
        if(!response.success) {
          reject(response.results)
        } else {
          resolve(response)
        }
      })
    })
  }

  get(enablingTypeCategoryId) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/registries/enablingTypeCategories/get', { id: enablingTypeCategoryId }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      })
    })
  }

  _buildRecentOperationObject(operation, enablingTypeCategory) {
    let routeParams;
    if (operation == 'update') {
      routeParams = { id: enablingTypeCategory.id }
    }

    let extraText;
    if (operation == 'update') {
      extraText = enablingTypeCategory.code
    }

    return {
      "text": operation == "create" ? "Aggiungi nuova Categoria tipi abilitazione" : "Modifica Categoria tipi abilitazione",
      "extraText": extraText,
      "name": operation == "create" ? "add_enablingTypeCategories" : "edit_enablingTypeCategories",
      "operationName": operation == "create" ? "add" : "edit",
      "registry": "enablingTypeCategories",
      "params": {
        enablingTypeCategory: enablingTypeCategory,
        routeName: operation == "create" ? 'EnablingTypeCategoriesGeneralNewFormTab' : 'EnablingTypeCategoriesGeneralEditFormTab',
        routeParams: routeParams
      }
    }
  }


  fields() {
    return Promise.resolve([
      { text: 'Nome', value: 'code' },
      { text: 'Descrizione', value: 'description' },
    ])
  }
}

export default new enablingTypeCategoryManagment();
