<template>
  <v-autocomplete
    max-width="10"
    width="10"
    v-model="localValue"
    :items="items"
    item-value="id"
    :item-text="field"
    return-object
    :label="labelFilter"
    filled
    deletable-chips
    dense
    chips
    small-chips
    hide-details="auto"
    :prepend-icon="icon"
  ></v-autocomplete>
</template>

<script>

export default {
  data() {
    let ls = { ...this.styles };
    return {
      localValue: this.value,
      icon: "",
      field: undefined,
      localStyle: ls,
      items: undefined,
    };
  },

  props: {  
    value: {
      type: Object,
      default: () => ({})
    },
    labelFilter: {
      type: String,
      default: "Seleziona Fornitore",
    },
    service: {
      type: String,
      default: "",
    },
    fielterName: {
      type: String,
      default: "",
    },
    fieldToShow: {
      type: [String, Function],
      default: "",
    },
    postIcon: {
      type: String,
      default: undefined,
    },
    preIcon: {
      type: String,
      default: undefined,
    },
    fetcher: {
      type: Function,
      default: () => {
        return new Promise(async (resolve) => {
          Promise.all([]);
        });
      },
    },
  },
  mounted() {
    (typeof this.fieldToShow == Function) ? this.field = this.fieldToShow(this.localValue) : this.field = this.fieldToShow;
    this.icon = this.preIcon;
    this.fetchItems();
  },
  methods: {
    fetchItems() {
      return new Promise(async (resolve, reject) => {
        this.fetcher()
          .then((results) => {
            this.items = results.rows;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  watch: {
    localValue(newVal) {
        this.$emit("input", newVal)
        this.$emit("change", newVal)
    },
    value(val) {
      if (val !== this.localValue) this.localValue = val;
    },
  },
};
</script>

<style>
</style>


