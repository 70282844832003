<template>
  <div>
    <SearchWithButton
      :search-text.sync="searchText"
      :search-on-enter="true"
      :placeholder="placeholder"
      expand-on-focus
      v-on:search="confirmSearch"
    >
      <template v-slot:search-button>
        <v-btn
          @click="confirmSearch"
          :disabled="!filterHasChanged"
          class="ml-2"
          fab
          small
          icon
        >
          <v-icon>mdi-search-web</v-icon>
        </v-btn>
        <v-btn @click="toggleFilters" v-if="showFilterButtons" class="ml-2" fab small icon>
          <v-badge
            bordered
            :dot="!viewFiltersNumber"
            :value="filtersActive"
            :content="filtersNumber"
          >
            <v-icon>mdi-filter</v-icon>
          </v-badge>
        </v-btn>
        <v-btn @click="resetFilters" v-if="showFilterButtons" class="ml-2" fab small icon>
          <v-icon>mdi-filter-off</v-icon>
        </v-btn>
      </template>
    </SearchWithButton>

    <v-banner
      transition="slide-y-transition"
      v-model="showFilter"
      class="filter-container"
    >
      <div class="d-flex flex-wrap">
        <template v-for="filter in localFilters">
          <div :key="filter.name">
            <FilterBoolean
              :labelFilter="filter.text"
              v-model="filter.value"
              class="px-3"
              :key="filter.name"
              v-if="filter.type == 'boolean'"
              @change="handleFilterChange($event, filter)"
            />

            <FilterDate
              v-model="filter.value"
              :labelFilter="filter.text"
              :pre-icon="filter.icon"
              class="py-3 px-3"
              :clearable="true"
              :key="filter.name"
              :styles="{ date: { width: '150px' } }"
              v-else-if="filter.type == 'date'"
              @change="handleFilterChange($event, filter)"
            >
            </FilterDate>

            <FilterString
              :pre-icon="filter.icon"
              v-model="filter.value"
              :labelFilter="filter.text"
              class="py-3 px-3"
              :key="filter.name"
              v-else-if="filter.type == 'string'"
              @change="handleFilterChange($event, filter)"
            />

            <!-- KEEP COMMENT
              <FilterAutocomplete       
              :pre-icon="filter.icon"
              :labelFilter="filter.text"
              v-model="filter.value"
              :service="filter.service"
              :fetcher="filter.fetcher"
              :filter-name="filter.name"
              :field-to-show="filter.fieldToShow"
              class="py-3 px-3"
              :key="filter.name"
              v-else-if="filter.type == 'object'"
              @change="handleFilterChange($event, filter)"
            />-->
          </div>
        </template>
      </div>

      <div class="d-flex justify-end">
        <v-btn text color="error" class="my-1" @click="resetFilters">
          Reset
        </v-btn>
        <v-btn
          text
          color="primary"
          class="my-1 mr-2"
          :disabled="!filterHasChanged"
          @click="confirmSearch"
        >
          Applica
        </v-btn>
      </div>
    </v-banner>
  </div>
</template>

<script>
import { SearchWithButton } from "likablehair-ui-components";
import { FilterBoolean } from "likablehair-ui-components";
import { FilterDate } from "likablehair-ui-components";
import FilterString from "@/components/common/filters/FilterString.vue";
import FilterAutocomplete from "@/components/common/filters/FilterAutocomplete.vue";

export default {
  name: "AdvancedSearch",
  components: {
    SearchWithButton,
    FilterBoolean,
    FilterDate,
    FilterString,
    FilterAutocomplete,
  },
  props: {
    filters: {
      type: Array,
      default: function () {
        return [];
      },
    },
    viewFiltersNumber: {
      type: Boolean,
      default: true,
    },
    searchTextProp: {
      type: String,
      default: "",
    },
    showFilterButtons: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: 'Search ...'
    },
  },
  data: function () {
    return {
      searchText: this.searchTextProp,
      filtersNumber: 0,
      filtersActive: false,
      activeFilters: {},
      localFilters: [...this.filters],
      showFilter: false,
      filterHasChanged: false,
    };
  },
  mounted: function () {
    this.recalculateActiveFilters()
  },
  beforeDestroy() {
    this.activeFilters = {};

    this.localFilters = [];
  },
  methods: {
    toggleFilters() {
      this.showFilter = !this.showFilter;
    },
    handleFilterChange(newVal, filter) {
      if (newVal !== "" && newVal !== undefined && filter.type != "object")
        this.recalculateActiveFilters();
      else if (
        filter.type == "object" &&
        filter.value != null &&
        filter.value.id != undefined
      )
        this.recalculateActiveFilters();
    },
    recalculateActiveFilters() {
      this.filtersNumber = 0;
      this.filtersActive = false;
      this.filterHasChanged = true;

      if (!this.searchText) this.activeFilters["searchText"] = null;
      else {
        this.activeFilters["searchText"] = this.searchText;
      }

      for (let i = 0; i < this.localFilters.length; i++) {
        if (
          (this.localFilters[i].type === "date" ||
            this.localFilters[i].type === "string") &&
          this.localFilters[i].value &&
          this.localFilters[i].value != ""
        ) {
          this.activeFilters[this.localFilters[i].name] =
            this.localFilters[i].value;
          this.filtersNumber++;
          this.filtersActive = true;
        } else if (
          this.localFilters[i].type === "boolean" &&
          this.localFilters[i].value != undefined
        ) {
          this.activeFilters[this.localFilters[i].name] =
            this.localFilters[i].value;
          if (this.localFilters[i].value !== false) this.filtersNumber++;
          this.filtersActive = true;
        } else if (
          this.localFilters[i].type === "object" &&
          this.localFilters[i].value &&
          this.localFilters[i].value.id != undefined
        ) {
          this.activeFilters[this.localFilters[i].name] =
            this.localFilters[i].value.id;
          this.filtersNumber++;
          this.filtersActive = true;
        } else if (!this.localFilters[i].value) {
          this.activeFilters[this.localFilters[i].name] = null;
        }
      }
      if (this.filtersNumber == 0) this.filtersActive = false;
    },
    confirmSearch() {
      this.filterHasChanged = false;
      this.$emit("apply", this.activeFilters);
    },
    resetFilters() {
      this.localFilters.forEach((filter) => {
        if (filter.type == "boolean") filter.value = undefined;
        else if (filter.type == "string") filter.value = "";
        else if (filter.type == "object") filter.value = { id: undefined };
        else if (filter.type == "date") filter.value = "";
      });
      this.activeFilters = {};
      this.filtersNumber = 0;
      this.filtersActive = false;
      this.confirmSearch();
    },
  },
  watch: {
    searchText() {
      this.recalculateActiveFilters();
    },

    filters(newVal) {
      this.localFilters = newVal;
      this.filters.map((filter) => {
        if (filter.type == "boolean" && filter.value == undefined)
          filter.value = false;
      });
      this.recalculateActiveFilters();
    },
  },
};
</script>

<style  >
.filter-container .v-banner__wrapper {
  padding: 0px !important;
}
</style>