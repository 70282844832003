<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Categorie Tipi Abilitazione"
    :route-father="routeFather"
  >
    <template v-slot:header-actions>
      <v-btn text link @click="goToNew">
        <v-icon class="mr-2">mdi-plus</v-icon> Nuovo
      </v-btn>
    </template>
    <template v-slot:toolbar-extension>
      <div class="d-flex justify-center align-center" style="width: 100%">
        <AdvancedSearch
          class="ml-2 mb-2"
          placeholder="Cerca Categoria Tipi Abilitazione ..."
          @apply="applyFilters"
          :show-filter-buttons="false"
        ></AdvancedSearch>
      </div>
    </template>
    <template v-slot:content>
      <div style="height: 100%; overflow-y: hidden" ref="dataTableWrapper">
        <TypeDataTable
          v-model="selected"
          loading-text="Caricamento Categorie Tipi Abilitazione"
          :headers="headers"
          :loading="loading"
          :items="filteredEnablingTypeCategories"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :height="dataTableHeight"
          @edit="handleEdit"
          @delete="handleDelete"
          :show-select="false"
          @dblclick:row="handleEditDoubleClick"
        ></TypeDataTable>
      </div>
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import StandardMenu from '@/components/common/StandardMenu.vue'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import AdvancedSearch from "@/components/common/AdvancedSearch.vue";
import { SearchBar } from "likablehair-ui-components";
import enablingTypeCategoriesService from '@/services/enablingTypeCategories/enablingTypeCategories.service.js'

export default {
  name: "EnablingTypeCategoriesRegistryList",
  components: {
    FullScreenDialog,
    SearchBar,
    TypeDataTable,
    StandardMenu,
    AdvancedSearch
  },
  data: function() {
    let props = this.$router.resolve({name: 'Registries'});

    return {
      dialog: true,
      loadingItems: false,
      loadingHeaders: false,
      routeFather: props.href,
      selected: undefined,
      dataTableHeightValue: 400,
      enablingTypeCategories: [],
      headers: [],
      page: 1,
      rowPerPage: 20,
      totalPages: 0,
      resizeListener: undefined,
    }
  },
  mounted: function() {
    this.fetchHeaders()
    this.fetchEnablingTypeCategories()

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    this.resizeListener = function() {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    }
    this.resizeListener = this.resizeListener.bind(this)
    window.addEventListener('resize', this.resizeListener)
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    fetchEnablingTypeCategories(filters) {
      this.loadingItems = true
      enablingTypeCategoriesService.list(this.page, this.rowPerPage, filters).then((results) => {
        if(this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage
        }

        if(this.page != results.page) {
          this.page = results.page
        }

        this.totalPages = results.totalPages
        if(this.totalPages < this.page) {
          this.page = this.totalPages
        }

        this.enablingTypeCategories = results.rows
        this.loadingItems = false
      })
    },
    fetchHeaders() {
      this.loadingHeaders = true
      enablingTypeCategoriesService.fields().then((headers) => {
        this.headers = headers
        this.loadingHeaders = false
      })
    },
    handleEdit(enablingTypeCategory) {
      this.$router.push({
        name: 'EnablingTypeCategoriesGeneralEditFormTab', 
        params: {
          id: enablingTypeCategory.id
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'EnablingTypeCategoriesRegistryList'}).href
        }
      })
    },
    handleEditDoubleClick(row, {item: enablingTypeCategory}) {
      this.$router.push({
        name: 'EnablingTypeCategoriesGeneralEditFormTab', 
        params: {
          id: enablingTypeCategory.id
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'EnablingTypeCategoriesRegistryList'}).href
        }
      })
    },
    handleDelete(enablingTypeCategory) {
      let confirmed = confirm('Sei sicuro di voler eliminare la categoria ' + enablingTypeCategory.code + ' ?')

      if(confirmed) {
        this.loading = true
        enablingTypeCategoriesService.delete(enablingTypeCategory).then(() => {
          this.fetchEnablingTypeCategories()
          this.loading = false
        })
      }
    },
    goToNew() {
      this.$router.push({
        name: 'EnablingTypeCategoriesGeneralNewFormTab', 
        query: {
          pathToGoBack: this.$router.resolve({name: 'EnablingTypeCategoriesRegistryList'}).href
        }
      })
    },
    applyFilters(filters) {
      this.fetchEnablingTypeCategories(filters);
    },
  },
  computed: {
    filteredEnablingTypeCategories() {
      return this.enablingTypeCategories
    },
    loading() {
      return this.loadingItems || this.loadingHeaders
    },
    dataTableHeight() {
      return this.dataTableHeightValue + 'px'
    }
  },
  watch: {
    page() {
      this.fetchEnablingTypeCategories()
    },
    rowPerPage() {
      this.fetchEnablingTypeCategories()
    },
  }
}
</script>

<style>

</style>